.App {
	text-align: center;
}

.App-header {
	background-color: #902a2b;
	min-height: 48px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.content {
	background-color: white;
	height: calc(100vh - 48px);
	display: flex;
}
