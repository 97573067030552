@font-face {
  font-family: "muli";
  src: url(./assets/font/Mulish-VariableFont_wght.ttf) format("opentype");
}

input {
  outline: none;
}

input::placeholder {
  /* color: #a6acaf; */
  color: #29282880;
  font-size: 12px;
  font-weight: 600;
  padding-left: 5px;
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #popStyle {
    margin-top: 2%;
    margin-left: 10%;
    width: 85%;
    min-height: 470px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 3px;
    opacity: 1;
    background-color: #ffffff;
  }
  #cross {
    font-size: 25px;
    color: white;
    margin-left: 59%;
    margin-top: 11px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
  #tableHead {
    background-color: #902a2b36;
    height: 60px;
    padding: 8px;
  }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
  #popStyle {
    margin-top: 2%;
    margin-left: 15%;
    width: 75%;
    min-height: 470px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
    border-radius: 3px;
    opacity: 1;
    background-color: #ffffff;
  }
  #cross {
    font-size: 25px;
    color: white;
    margin-left: 67%;
    margin-top: 11px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
  #tableHead {
    background-color: #902a2b36;
    height: 60px;
    padding: 8px;
  }
}
@media screen and (min-device-width: 1201px) and (max-device-width: 1343px) {
  #popStyle {
    margin-top: 2%;
    width: 66%;
    min-height: 500px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    opacity: 1;
    background-color: #ffffff;
    margin-left: 18%;
  }
  #cross {
    font-size: 25px;
    color: white;
    margin-left: 67%;
    margin-top: 11px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
  #tableHead {
    background-color: #902a2b36;
    height: 62px;
    padding: 8px;
  }
}
@media screen and (min-device-width: 1344px) {
  #popStyle {
    margin-top: 2%;
    margin-left: 20%;
    width: 63%;
    min-height: 500px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 3px;
    opacity: 1;
    background-color: #ffffff;
  }
  #cross {
    font-size: 25px;
    color: white;
    margin-left: 64%;
    margin-top: 11px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
  }
  #tableHead {
    background-color: #902a2b36;
    height: 60px;
    padding: 8px;
  }
}

#tableActionButton::before {
  transition: all 0.2s ease-in-out;
  content: attr(data);
}

#tableActionButton:hover::before {
  content: attr(data-hover);
}

#tableActionButton:hover {
  background-color: #902a2b !important;
  color: #ffffff !important;
}

#activeTabUnderline {
  color: #902a2b;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}

#activeTabUnderline:after {
  /* background: none repeat scroll 0 0 transparent; */
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #902a2b;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
#activeTabUnderline:hover:after {
  width: 100%;
  left: 0;
}

#activeTabUnderline:hover {
  color: #000000d9 !important;
}

.refresh-icon {
  transition: all 0.2s ease-in-out;
}

.refresh-icon:hover {
  transform: rotate(90deg);
  transition: all 0.3s ease-in-out;
}

.refresh-icon:active {
  color: #902a2b !important;
  transition: all 0.1s ease-out;
  font-size: 36px !important;
}

.captcha-input:valid {
  transition: text 0.3s ease-in-out !important;
}
