.container {
	display: flex;
	align-items: center;
	padding-left: 30px;
	padding-top: 24px;
	height: 60px;
}

.header-icon {
	margin-right: 12px;
	color: #902a2b;
	width: 30px !important;
	height: 30px !important;
}

.content-title {
	font-size: 26px;
	color: #292828D8;
	font-weight: bold;
}
