body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: sans-serif;
}

button {
  text-transform: initial !important;
  font-family: sans-serif;
}

.pointer {
  cursor: pointer;
}

.status-cell {
  display: flex;
  align-items: center;
}

.status-active {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: green;
  display: inline-block;
  margin-right: 8px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #902a2b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ag grid style overrides */

.MuiDataGrid-columnHeaders {
  background-color: #902a2b2b;
  border: 1px solid #377d222b;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.even-row {
  background-color: #902a2b0f;
}

.MuiDataGrid-columnHeader {
  font-size: 20px;
  font-weight: 600;
}

.MuiDataGrid-main {
  font-family: sans-serif;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}

.MuiDataGrid-cell {
  padding-left: 24px !important;
}

.MuiDataGrid-root {
  border: none !important;
}
