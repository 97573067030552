.home-container {
  width: 100%;
}

.menu-link {
  font-size: 14px;
  padding: 14px 12px;
  border-top: 1px solid #902a2b4d;
  flex: 1;
  display: flex;
}

.menu-link a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: inherit;
}

.menu-link:first-child {
  border-top: none;
}

.links-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-container {
  padding: 10%;
}

.title {
  text-align: left;
}

.home-cards {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.home-cards .card-container {
  margin: 20px;
  width: 44%;
  max-width: 500px;
}

.right-card {
  padding: 14px 12px;
}

.form-container {
  margin-top: 20px;
  padding: 0 14%;
}

.form-field {
  margin-top: 25px;
}

.submit-btn {
  margin-top: 25px;
  text-align: right;
}

.submit-btn a {
  border-radius: 16px;
  padding: 6px 12px;
  background-color: #902a2b;
  color: white;
  text-decoration: none;
}

.menu-icon svg {
  width: 40px;
  height: 40px;
  font-size: 14px;
}
.menu-icon {
  margin-right: 8px;
  height: 40px;
}

.title {
  display: flex;
  align-items: center;
  color: #292828d9;
  font-weight: 600;
}
