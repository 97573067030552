.details-header {
  position: relative;
  height: 60px;
  box-shadow: 0px 3px 6px #902a2b26;
  border: 1px solid #902a2b52;
}
.details-tab {
  position: absolute;
  /* bottom: 5px; */
  left: 50px;
  height: 60px;
  width: 200px;
  background: #96939323;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #656565;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  /* border: 1px solid #65656584; */
}
.details-tab:before {
  content: "";
  width: 0;
  height: 0;
  border-width: 30px 30px;
  border-style: solid;
  border-color: #96939323 #96939323 #96939323 transparent;
  position: absolute;
  left: -60px;
}
.details-tab:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 30px solid #96939323;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  right: -30px;
}
.selected:before {
  border-color: #902a2b #902a2b #902a2b transparent;
}
.selected:after {
  border-left: 30px solid #902a2b;
}
.selected {
  background-color: #902a2b;
  color: white;
  font-weight: 600;
}
