

@font-face {
    font-family: "muli";
    src: url(../../assets/font1/SourceSansPro_Bold.ttf) format("opentype");
  }
  @font-face {
    font-family: "muliSemi";
    src: url(../../assets/font1/SourceSansPro_SemiBold.ttf) format("opentype");
  }
  @font-face {
    font-family: "muliReg";
    src: url(../../assets/font1/SourceSansPro_Regular.ttf) format("opentype");
  }
/*   
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #154272;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #154272;
  }
   */
  .dropdown {
    float: left;
    overflow: hidden;
  }
  
  .dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    margin: 5px 0px;
  }
  
  .dropdown-content {
    display: none;
    width:24%;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 165px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    margin-left: -16%;
  }
  @media screen and (max-width:480px){
    .dropdown:hover .dropdown-content {
      display: block;
      margin-left: -29%;
    }
    #refresh{
      margin-top: -10%;
    }
    #desktopuser{
      display: none;
    }
  }
  
  /* For Desktop View */
  @media screen and (min-width: 1201px) {
    #contTop {
      background-color: none;
      margin-top: -46%;
    }
  
    #dropsystem5{ 
      margin-top: 0%;
      margin-left: 8%;
      display: none;
    }
    #eyeSymbol{
      margin-top: -16%;
      margin-left: 90%;
    }
    #cardDataDiploma1{
      width:50%
    }
    .Area {
      width: 100%;
      height: 100%;
    }
    #humburg{
      animation-duration: 3s;
      animation-name: slidein;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    #enterText{
      font-size: 20px;
    }
    #documentLogo{
      width:100%;
    }
    #underDiv1{
      margin-left: 30px;
    }
    #refresh{
      margin-top: -10%;
    }
    #bannerState{
      margin-left:12%;
      text-align: left;
      width:70%;
    }
  
    #lengthdefine{
      width:96%;
    }
    #pdfshown{
      margin-left: 165px;
      margin-top: 50px;
      height: 300px;
      overflow-y: scroll;
    }
    #Dobinput{
      width: 75%
    }
    #syncDigiData{
      width: 28%;
      position: absolute;
      margin-left: 54%;
      margin-top: 50px;
    }
    #header_alignment{
      margin-left: 33%;
    }
    #boxes1{
      margin-top: 20%;
      margin-left: 7rem;
    }
    #boxes{
      width:65%
    }
    #MobileApp{
      margin-top: 100px;
    }
    #MobileApp p{
      font-size:17px;
      width: 100%;
      margin-left:52%;
    }
    #MobileApp #img1{
      width:70px;
      margin-left: 83%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding:7px;
      border-radius:5px
    }
  
    #MobileApp #img2{
      width:70px;
      margin-left: 3%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding: 7px;
      border-radius:5px;
    }
    #boxesOTP{
      width: 65%;
    }
    #box1{
      width:35%;
    }
    #box1OTP{
      width: 35%;
    }
    #signInButton{
      margin-left: 57%;
    }
    #box1 {
      margin-bottom: 100px;
    }
    #box1OTP{
      margin-bottom: 100px;
    }
    .cancelbutton{
      margin-left: 62%;
    }
    #cardData {
      width: 100%;
      margin-left: -43px;
      margin-top: 0rem;
    }
    #cardDataEsevaicard{
      width:44%;
      margin-left: 25%;
      margin-top: 0rem;
    }
    #downloadButton{
      margin-left: 50%;
    }
    .DocumentDetail{
      display: flex;
    }
    #boxShod{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      margin-left: 2%;
      padding: 20px;
    }
    #eduSection{
      display: flex;
    }
    #contTop1 {
      background-color: none;
      margin-top: 2%;
      display: flex;
    }
    #submitButton {
      cursor: pointer;
      border: 2px solid #144272;
      border-radius: 10px;
      background-color: #144272;
      color: white;
      padding: 5px 30px;
      font-weight: bold;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    #statefont {
      color: #505050;
      font-size: 17px;
      text-align: center;
      margin-top: 5%
    }
    #imgEdu {
      width: 7%;
      margin-top: 7px;
    }
    .fontEdu {
      font-size: 29px;
      color: #292828d8;
    }
    #underDiv{
      margin-left: 19%;
    }
    #username{
      display: hidden;
    }
    #userlogo{
      margin-right: 1%;
    }
    #ServBox {
      margin-left: -1%;
      display: grid;
    }
    #ServBox1 {
      margin-left: -1%;
      display: none;
    }
    #dropsystem1{
      display: none;
    }
    #dropsystem2{
      display: none;
    }
    #dr
    #dropsystem{
      margin-left: 42px;
    }
    #logoHeader{
      width: 7%;
      height: 117%;
      margin-top: -9px;
    }
    #header {
      margin-left: 60%;
    }
    .dataTamil {
      font-size: 24px;
      margin-top: 25px;
    }
    #imgLogo {
      width: 90px;
      height: 90px;
    }
    #cardbox {
      margin-top: -1%;
      width: 90%;
      margin-left: 0%;
      height: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 10px;
    }
  
    #cardDataEsevai {
      width: 113%;
      margin-left: -52px;
    }
    #cardDataDiploma {
      width: 113%;
      margin-left: -52px;
      margin-top: -3rem;
    }
  } 
  
  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    #contTop {
      background-color: none;
      margin-top: -46%;
    }
    #dropsystem5{ 
      margin-top: 0%;
      margin-left: 8%;
      display: none;
    }
    #eyeSymbol{
      margin-top: -11%;
      margin-left: 90%;
    }
    .Area {
      width: 100%;
      height: 100%;
    }
    #humburg{
      animation-duration: 3s;
      animation-name: slidein;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    #enterText{
      font-size: 20px;
    }
    #documentLogo{
      width:100%;
    }
    #underDiv1{
      margin-left: 30px;
    }
    #refresh{
      margin-top: -10%;
    }
    #bannerState{
      margin-left:12%;
      text-align: left;
      width:70%;
    }
  
    #lengthdefine{
      width:96%;
    }
    #pdfshown{
      margin-left: 165px;
      margin-top: 50px;
      height: 300px;
      overflow-y: scroll;
    }
    #Dobinput{
      width: 75%
    }
    #syncDigiData{
      width: 28%;
      position: absolute;
      margin-left: 54%;
      margin-top: 50px;
    }
    #header_alignment{
      margin-left: 28%;
    }
    #boxes1{
      margin-top: 20%;
      margin-left: 7rem;
    }
    #boxes{
      width:65%
    }
    #MobileApp{
      margin-top: 100px;
    }
    #MobileApp p{
      font-size:17px;
      width: 100%;
      margin-left:52%;
    }
    #MobileApp #img1{
      width:70px;
      margin-left: 83%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding:7px;
      border-radius:5px
    }
  
    #MobileApp #img2{
      width:70px;
      margin-left: 3%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding: 7px;
      border-radius:5px;
    }
    #boxesOTP{
      width: 65%;
    }
    #box1{
      width:35%;
    }
    #box1OTP{
      width: 35%;
    }
    #signInButton{
      margin-left: 50%;
    }
    #box1 {
      margin-bottom: 100px;
    }
    #box1OTP{
      margin-bottom: 100px;
    }
    .cancelbutton{
      margin-left: 62%;
    }
    #cardData {
      width: 100%;
      margin-left: -43px;
      margin-top: 0rem;
    }
    #cardDataEsevaicard{
      width: 73%;
      margin-left: 14%;
      margin-top: 0rem;
    }
    #downloadButton{
      margin-left: 50%;
    }
    .DocumentDetail{
      display: flex;
    }
    #boxShod{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      margin-left: 2%;
      padding: 20px;
    }
    #eduSection{
      display: flex;
    }
    #contTop1 {
      background-color: none;
      margin-top: 2%;
      display: flex;
    }
    #submitButton {
      cursor: pointer;
      border: 2px solid #144272;
      border-radius: 10px;
      background-color: #144272;
      color: white;
      padding: 5px 30px;
      font-weight: bold;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    #statefont {
      color: #505050;
      font-size: 17px;
      text-align: center;
    }
    #imgEdu {
      width: 7%;
      margin-top: 7px;
    }
    .fontEdu {
      font-size: 29px;
      color: #292828d8;
    }
    #underDiv{
      margin-left: 19%;
    }
    #username{
      display: hidden;
    }
    #userlogo{
      margin-right: 3%;
    }
    #ServBox {
      margin-left: -1%;
      display: grid;
    }
    #ServBox1 {
      margin-left: -1%;
      display: none;
    }
    #dropsystem1{
      display: none;
    }
    #dropsystem2{
      display: none;
    }
    #dr
    #dropsystem{
      margin-left: 42px;
    }
    #logoHeader{
      width: 7%;
      height: 117%;
      margin-top: -9px;
    }
    #header {
      margin-left: 60%;
    }
    .dataTamil {
      font-size: 24px;
      margin-top: 25px;
    }
    #imgLogo {
      width: 90px;
      height: 90px;
    }
    #cardbox {
      margin-top: -1%;
      width: 90%;
      margin-left: 0%;
      height: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 10px;
    }
  
    #cardDataEsevai {
      width: 178%;
      margin-left: -122px;
    }
    #cardDataDiploma {
      width: 193%;
      margin-left: -137px;
      margin-top: -3rem;
  
    }
  }
  
  /* For Tablet View */
  @media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
    #contTop {
      background-color: none;
      margin-top: -78%;
    }
    #dropsystem5{ 
      margin-top: 0%;
      margin-left: 8%;
      display: none;
    }
    #eyeSymbol{
      margin-top: -11%;
      margin-left: 90%;
    }
    #Dobinput{
      width:75%;
    }
    .Area {
      width: 100%;
      height: 100%;
    }
    #humburg{
      animation-duration: 3s;
      animation-name: slidein;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    #enterText{
      font-size: 20px;
    }
    #documentLogo{
      width:100%;
    }
    #underDiv1{
      margin-left: 9%;
    }
    #refresh{
      margin-top: -13%;
    }
    #MobileApp{
      margin-top: 100px;
    }
    #bannerState{
      margin-left:12%;
      text-align: left;
    }
    #banner_imglast{
      margin-top: 16%;
    }
  
    #cardDataDiploma1{
      width: 350px;
      margin-left: -2rem;
      border:none
    }
    #MobileApp p{
      font-size:17px;
      width: 100%;
      margin-left:52%;
    }
    #MobileApp #img1{
      width:70px;
      margin-left: 83%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding:7px;
      border-radius:5px
    }
  
    #MobileApp #img2{
      width:70px;
      margin-left: 3%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding: 7px;
      border-radius:5px;
    }
    #header_alignment{
      margin-left: 11%;
    }
    #dropsystem1{
      display: none;
    }
    #syncDigiData{
      width: 74%;
      /* position: fixed; */
      margin-left: 26%;
      margin-top: -6%;
      margin-bottom: 3rem;
    }
    #boxes1{
      margin-top: 20%;
      margin-left: 2rem;
    }
    #boxes{
      width:90%;
      margin-left: 3%;
    }
    #boxesOTP{
      width: 80%;
      margin-left:  3%;
    }
    #box1{
      width:100%;
      margin-top: 0rem;
    }
    #box1OTP{
      width: 86%;
      margin-left: 10%;
      margin-top: 2rem;
    }
    #bannerArea{
      margin-top: 0rem;
    }
    #contTop1 {
      background-color: none;
      margin-top: 2%;
      display: flex;
    }
    #signInButton{
      margin-left: 35%;
    }
    .cancelbutton{
      margin-left: 68%;
    }
    #cardData {
      width: 100%;
      margin-left: -41px;
    }
  
    #cardDataEsevaicard{    
      width: 71%;
      margin-left: 15%;
      margin-top: 0rem;
  
    }
    #downloadButton{
      margin-left: 15%;
    }
    .DocumentDetail{
      display: block;
    }
    #boxShod{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      margin-left: 5%;
      padding:5px;
    }
    #eduSection{
      display: block;
    }
    #submitButton {
      cursor: pointer;
      margin-left: 10%;
      border: 2px solid #144272;
      border-radius: 10px;
      background-color: #144272;
      color: white;
      padding: 5px 30px;
      font-weight: bold;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    #formField{
      display: block;
    }
    #imgEdu1 {
      width: 14%;
      height: 5%;
      margin-top: -1px;
    }
    #statefont{
      margin-top: 4%;
    }
    #boxflex {
      width: 93%;
      margin-left: -25px;
    }
    #imgEdu {
      width: 12%;
    }
    .fontEdu {
      font-size: 29px;
      color: #292828d8;
      margin-top: 3%;
    }
    #ServBox {
      margin-left: -1%;
      display: none;
    }
    #ServBox1 {
      margin-left: -1%;
      display: block;
    }
    #userlogo{
      margin-left: -10%;
    }
    #username{
      display: hidden;
      margin-right: 51px;
    }
    #dropsystem{
      display: none;
    }
    #logoHeader{
      width: 10%;
      height: 89%;
      margin-top: 1%;
    }
    #underDiv{
      margin-left: 9%;
    }
    #header {
      margin-left: 66%;
    }
    #imgLogo {
      width: 80px;
      height: 80px;
      margin-top: 22px;
    }
    .dataTamil {
      font-size: 20px;
      padding-top: 16px;
    }
    #boxboard {
      margin-top: 92px;
      margin-left: 20%;
    }
    .textmain {
      font-size: 15px;
    }
    #cardbox {
      margin-top: -1%;
      width: 85%;
      margin-left: 7%;
      height: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 10px;
    }
  
    #cardDataEsevai {
      width: 211%;
      margin-left: -116px;
      border-bottom:5px solid #154272;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    #cardDataDiploma {
      width: 200%;
      margin-left: -129px;
      border-bottom:5px solid #154272;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }
  
  @media screen and (min-width: 481px) and (max-device-width: 768px){
    #contTop {
      background-color: none;
      margin-top: -78%;
    }
    #dropsystem5{ 
      margin-top: 0%;
      margin-left: 8%;
      display: none;
    }
    #eyeSymbol{
      margin-top: -11%;
      margin-left: 90%;
    }
    .Area {
    width: 105%;
    height: 100%;
  }
  .Area {
    width: 100%;
    height: 100%;
  }
    #humburg{
      animation-duration: 3s;
      animation-name: slidein;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    #enterText{
      font-size: 20px;
    }
    #documentLogo{
      width:100%;
    }
    #underDiv1{
      margin-left: 12%;
    }
    #refresh{
      margin-top: -11%;
    }
  
    #bannerState{
      margin-left:12%;
      text-align: left;
    }
  
    #lengthdefine{
      width:202%;
    }
    #banner_imglast{
      margin-top: 59%;
    }
    #MobileApp{
      margin-top: 170px;
    }
    #MobileApp p{
      font-size:17px;
      width: 100%;
      margin-left:2%;
    }
    #MobileApp #img1{
      width:70px;
      margin-left: 29%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding:7px;
      border-radius:5px
    }
  
    #MobileApp #img2{
      width:70px;
      margin-left: 3%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding: 7px;
      border-radius:5px;
    }
    #formField{
      display: block;
      margin-top: -1rem;
    }
    #formField input{
      width:85%;
    }
    #formField select{
      width:85%;
    }
    #dropsystem1{
      margin-left: 10%;
      margin-top: 6%;
      /* display: none; */
    }
    #SeenPrivacy{
      display: none;
    }
    #dropsystem2{
      display: none;
    }
    #dr
    #syncDigiData{
      width: 73%;
      position: fixed;
      margin-left: 7%;
      margin-top: -76%;
    }
    #boxes1{
      margin-top: 0%;
      margin-left: 0rem;
    }
    #logoTitle{
      /* display: none; */
    }
    #boxes{
      width:60%;
      margin-left: 0%;
    }
  
    #boxesOTP{
      width: 96%;
      margin-left: 3%;
    }
    #box1{
      width:60%;
      margin-left: 0%;
      margin-top: 22%;
    }
    #box1OTP{
      width:96%;
      margin-left: 5%;
      margin-top: 3%;
    
    }
    #bannerArea{
      margin-top: 0rem;
    }
    #contTop1 {
      background-color: none;
      margin-top: 2%;
      display: grid;
    }
    #signInButton{
      margin-left: 27%;
    }
    .cancelbutton{
      margin-left: 5%;
    }
    #cardData {
      width: 100%;
      margin-left: -23px;
    }
  
    #cardDataEsevaicard{
      width: 86%;
      margin-left: 7%;
      margin-top: 0rem;
    }
    #cardDataEsevai {
      width: 199%;
      margin-left: -127px;
    }
    #cardDataDiploma{
      width: 337px;
      margin-left: -133px;
    }
    #cardDataDiploma1{
      width: 79%;
      padding: 67px;
      margin-left: 38px;
    }
    #cardUnder{
      width: 283px;
      margin-left: -2rem;
    }
    #downloadButton{
      margin-left: 13%;
    }
    .DocumentDetail{
      display: block;
    }
    #boxShod{
      box-shadow: none;
      margin-left: 12%;
    }
    #eduSection{
      display: block;
    }
    #submitButton {
      cursor: pointer;
      border: 2px solid #144272;
      border-radius: 10px;
      background-color: #144272;
      color: white;
      padding: 5px 30px;
      font-weight: bold;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    #imgEdu1 {
      width: 24%;
      height: 5%;
      margin-top: 0px;
    }
    #statefont{
      margin-top: 8%;
  
    }
    #statefont1{
      margin-top: -0.5rem;
    }
    #boxflex {
      width: 90%;
      margin-left: -25px;
    }
    #imgEdu {
      width: 17%;
    }
    .fontEdu {
      font-size: 20px;
      color: #292828d8;
      margin-top: 5%;
    }
    #ServBox {
      margin-left: -1%;
      display: none;
    }
    #ServBox1 {
      margin-left: -1%;
      display: block;
    }
    #userlogo{
      margin-left: 26%;
      display: none;
    }
    #dropsystem{
      display: none;
    }
    #logoHeader{
      width: 15%;
      height: 95%;
      margin-top: -1%;
    }
    #desktopuser{
      margin-left: 6%;
      display: none;
    }
    #username{
      display: none;
    }
    #underDiv{
      margin-left: 11.5%;
    }
    #header {
      margin-left: 66%;
    }
    #imgLogo {
      width: 60px;
      height: 55px;
      margin-top: 22px;
    }
    .dataTamil {
      font-size: 20px;
      padding-top: 16px;
      margin-left: 2rem;
    }
    #boxboard {
      margin-top: 92px;
      margin-left: 48px;
    }
    .textmain {
      font-size: 15px;
    }
    #cardbox {
      margin-top: -1%;
      width: 88%;
      margin-left: 5%;
      height: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 10px;
    }
  }
  
  @media screen and (min-width: 360px) and (max-device-width: 480px){
    #contTop {
      background-color: none;
      margin-top: -78%;
    }
    #eyeSymbol{
      margin-top: -23%;
      margin-left: 75%;
    }
    #certData{
      display: block;
    }
    #shareText{
      margin-left: -41%;
    }
    .Area {
      width: 100%;
      height: 100%;
    }
    #SeenPrivacy{
      display: none;
    }
    #humburg{
      animation-duration: 3s;
      animation-name: slidein;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    #enterText{
      font-size: 20px;
    }
    #documentLogo{
      width:100%;
    }
    #underDiv1{
      margin-left: 30px;
    }
    #refresh{
      margin-top: -16%;
    }
  
    #bannerState{
      margin-left:4%;
      text-align: left;
    }
  
    #lengthdefine{
      width:202%;
    }
    #banner_imglast{
      margin-top: 59%;
    }
    #MobileApp{
      margin-top: 170px;
    }
    #MobileApp p{
      font-size:17px;
      width: 100%;
      margin-left:2%;
    }
    #MobileApp #img1{
      width:70px;
      margin-left: 29%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding:7px;
      border-radius:5px
    }
  
    #MobileApp #img2{
      width:70px;
      margin-left: 3%;
      margin-top: 20px;
      cursor: pointer;
      border:2px solid grey;
      padding: 7px;
      border-radius:5px;
    }
    #formField{
      display: block;
      margin-top: -1rem;
    }
    #formField input{
      width:85%;
    }
    #formField select{
      width:85%;
    }
    #dropsystem1{
      margin-top: 8%;
      margin-left: 8%;
    }
    #dropsystem5{ 
      margin-top: 0%;
      margin-left: 8%;
    }
    #dropsystem2{
      display: none;
    }
    #dr
    #syncDigiData{
      width: 73%;
      position: fixed;
      margin-left: 7%;
      margin-top: -76%;
    }
    #boxes1{
      margin-top: 0%;
      margin-left: 0rem;
    }
    #logoTitle{
      /* display: none; */
    }
    #boxes{
      width:63%;
      margin-left: 0%;
    }
  
    #boxesOTP{
      width: 96%;
      margin-left: 3%;
    }
    #box1{
      width:63%;
      margin-left: 0%;
      margin-top: 39%;
    }
    #box1OTP{
      width:96%;
      margin-left: 5%;
      margin-top: 3%;
    
    }
    #bannerArea{
      margin-top: 0rem;
    }
    #contTop1 {
      background-color: none;
      margin-top: 2%;
      display: grid;
    }
    #signInButton{
      margin-left: 10%;
    }
    .cancelbutton{
      margin-left: 5%;
    }
    #cardData {
      width: 100%;
      margin-left: -23px;
    }
  
    #cardDataEsevaicard{
      width: 112%;
      margin-left: -6%;
      margin-top: 0rem;
    }
    #cardDataEsevai {
      width: 295%;
      margin-left: -127px;
    }
    #cardDataDiploma{
      width: 337px;
      margin-left: -133px;
    }
    #cardDataDiploma1{
      width: 93%;
      margin-left: 38px;
      padding: 64px;
    }
    #cardUnder{
      width: 283px;
      margin-left: -2rem;
    }
    #downloadButton{
      margin-left: 4%;
    }
    .DocumentDetail{
      display: block;
    }
    #boxShod{
      box-shadow: none;
      margin-left: 0%;
    }
    #eduSection{
      display: block;
    }
    #submitButton {
      cursor: pointer;
      border: 2px solid #144272;
      border-radius: 10px;
      background-color: #144272;
      color: white;
      padding: 5px 30px;
      font-weight: bold;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    #imgEdu1 {
      width: 24%;
      height: 5%;
      margin-top: 0px;
    }
    #statefont{
      margin-top: 14px;
    }
    #statefont1{
      margin-top: -0.5rem;
    }
    #boxflex {
      width: 90%;
      margin-left: -25px;
    }
    #imgEdu {
      width: 17%;
    }
    .fontEdu {
      font-size: 20px;
      color: #292828d8;
      margin-top: 3%;
    }
    #ServBox {
      margin-left: -1%;
      display: none;
    }
    #ServBox1 {
      margin-left: -1%;
      display: block;
    }
    #userlogo{
      margin-left: 26%;
      display: none;
    }
    #dropsystem{
      display: none;
    }
    #logoHeader{
      width: 22%;
      height: 75%;
      margin-top: 5px;
    }
    #username{
      display: none;
    }
    #underDiv{
      margin-left: 11.5%;
    }
    #header {
      margin-left: 66%;
    }
    #imgLogo {
      width: 60px;
      height: 55px;
      margin-top: 22px;
    }
    .dataTamil {
      font-size: 20px;
      padding-top: 16px;
      margin-left: -1rem;
    }
    #boxboard {
      margin-top: 92px;
      margin-left: 48px;
    }
    .textmain {
      font-size: 15px;
    }
    #cardbox {
      margin-top: -1%;
      width: 88%;
      margin-left: 5%;
      height: 100%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border-radius: 10px;
    }
  }
  
  
  
  
  
  
  .headerLayer {
    width: 100%;
    height: 7%;
    background-color: #144272;
  }
  .footerLayer {
    width: 100%;
  }
  
  .refresh-icon {
    transition: all 0.2s ease-in-out;
  }
  
  .refresh-icon:hover {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
  }
  
  .refresh-icon:active {
    color: #902a2b !important;
    transition: all 0.1s ease-out;
    font-size: 36px !important;
  }
  